var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "qualification_content" }, [
    _c("h2", [_vm._v("店铺资质要求")]),
    JSON.stringify(_vm.storeRequirement) !== "{}"
      ? _c("ul", [
          _vm._m(0),
          _c("li", [
            _c("div", { staticClass: "title" }, [_vm._v(" 供应商所属馆 ")]),
            _c("div", { staticClass: "requirement" }, [
              _vm._v(" " + _vm._s(_vm.storeRequirement.siteName) + " "),
            ]),
            _c("div", { staticClass: "line" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.storeRequirement.siteIsOrNot == 0 ? "不符合" : "符合"
                  ) +
                  " "
              ),
            ]),
          ]),
          _c("li", [
            _c("div", { staticClass: "title" }, [_vm._v(" 店铺经营时间 ")]),
            _c("div", { staticClass: "requirement" }, [
              _vm._v(
                " 大于等于 " +
                  _vm._s(_vm.storeRequirement.storeOperationTime) +
                  " "
              ),
            ]),
            _c("div", { staticClass: "line" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.storeRequirement.operationTimeIsOrNot == 0
                      ? "不符合"
                      : "符合"
                  ) +
                  " "
              ),
            ]),
          ]),
          _c("li", [
            _c("div", { staticClass: "title" }, [_vm._v(" 店铺评分 ")]),
            _c("div", { staticClass: "requirement" }, [
              _vm._v(
                " 大于等于 " + _vm._s(_vm.storeRequirement.storeStar) + " "
              ),
            ]),
            _c("div", { staticClass: "line" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.storeRequirement.storeStarIsOrNot == 0
                      ? "不符合"
                      : "符合"
                  ) +
                  " "
              ),
            ]),
          ]),
          _c("li", [
            _c("div", { staticClass: "title" }, [_vm._v(" 商家服务 ")]),
            _c("div", { staticClass: "requirement" }, [
              _vm._v(
                " 大于等于 " + _vm._s(_vm.storeRequirement.supplierStar) + " "
              ),
            ]),
            _c("div", { staticClass: "line" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.storeRequirement.supplierStarIsOrNot == 0
                      ? "不符合"
                      : "符合"
                  ) +
                  " "
              ),
            ]),
          ]),
          _c("li", [
            _c("div", { staticClass: "title" }, [_vm._v(" 发货速度 ")]),
            _c("div", { staticClass: "requirement" }, [
              _vm._v(
                " 大于等于 " + _vm._s(_vm.storeRequirement.shippingStar) + " "
              ),
            ]),
            _c("div", { staticClass: "line" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.storeRequirement.shippingStarIsOrNot == 0
                      ? "不符合"
                      : "符合"
                  ) +
                  " "
              ),
            ]),
          ]),
        ])
      : _vm._e(),
    _c("h2", [_vm._v("商品资质要求")]),
    JSON.stringify(_vm.goodsRequirement) !== "{}"
      ? _c("ul", [
          _vm._m(1),
          _c("li", [
            _c("div", { staticClass: "title" }, [_vm._v(" 商品类目 ")]),
            _c("div", { staticClass: "requirement" }, [
              _vm._v(" " + _vm._s(_vm.goodsRequirement.goodsLevelName) + " "),
            ]),
          ]),
          _c("li", [
            _c("div", { staticClass: "title" }, [_vm._v(" 价格区间 ")]),
            _c("div", { staticClass: "requirement" }, [
              _vm._v(
                " " +
                  _vm._s(_vm.goodsRequirement.lowPrice) +
                  " - " +
                  _vm._s(_vm.goodsRequirement.highPrice) +
                  " "
              ),
            ]),
          ]),
          _c("li", [
            _c("div", { staticClass: "title" }, [
              _vm._v(" 商品近30天的评价量 "),
            ]),
            _c("div", { staticClass: "requirement" }, [
              _vm._v(
                " 大于等于 " +
                  _vm._s(_vm.goodsRequirement.goodsCommentNum) +
                  " "
              ),
            ]),
          ]),
          _c("li", [
            _c("div", { staticClass: "title" }, [
              _vm._v(" 商品的历史累计销量 "),
            ]),
            _c("div", { staticClass: "requirement" }, [
              _vm._v(
                " 大于等于 " + _vm._s(_vm.goodsRequirement.salesSum) + " "
              ),
            ]),
          ]),
          _c("li", [
            _c("div", { staticClass: "title" }, [_vm._v(" 商品评分 ")]),
            _c("div", { staticClass: "requirement" }, [
              _vm._v(
                " 大于等于 " + _vm._s(_vm.goodsRequirement.goodsStar) + " "
              ),
            ]),
          ]),
          _c("li", [
            _c("div", { staticClass: "title" }, [_vm._v(" 商品包邮 ")]),
            _c("div", { staticClass: "requirement" }, [
              _vm._v(
                " " +
                  _vm._s(_vm.goodsRequirement.isShip == 0 ? "是" : "否") +
                  " "
              ),
            ]),
          ]),
        ])
      : _vm._e(),
    !_vm.isCsfr &&
    (_vm.goodsRequirement?.discountsValue ||
      _vm.goodsRequirement?.discountsValue)
      ? _c("div", [
          _c("h2", [_vm._v("商品报名要求")]),
          _c("ul", [
            _vm.goodsRequirement.discountsValue
              ? _c("li", [
                  _c("div", { staticClass: "title" }, [_vm._v(" 活动折扣 ")]),
                  _c("div", { staticClass: "requirement" }, [
                    _vm._v(
                      " 折扣须不低于 " +
                        _vm._s(
                          (_vm.goodsRequirement.discountsValue * 100) / 10
                        ) +
                        " 折 "
                    ),
                  ]),
                ])
              : _vm._e(),
            _vm.goodsRequirement.promStock
              ? _c("li", [
                  _c("div", { staticClass: "title" }, [_vm._v(" 活动库存 ")]),
                  _c("div", { staticClass: "requirement" }, [
                    _vm._v(
                      " 库存须不低于 " +
                        _vm._s(_vm.goodsRequirement.promStock) +
                        " "
                    ),
                  ]),
                ])
              : _vm._e(),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", [
      _c("div", { staticClass: "title" }, [_vm._v(" 商家资质名称 ")]),
      _c("div", { staticClass: "requirement" }, [_vm._v(" 资质要求 ")]),
      _c("div", { staticClass: "line" }, [_vm._v(" 符合/不符合 ")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", [
      _c("div", { staticClass: "title" }, [_vm._v(" 商品资质 ")]),
      _c("div", { staticClass: "requirement" }, [_vm._v(" 资质要求说明 ")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }