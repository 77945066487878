<template>
  <div class="activity_detail_content">
    <page-content>
      <div
        v-if="JSON.stringify(activityMsg) != '{}'"
        class="activity_msg"
      >
        <div class="top_content">
          <div class="left_img">
            <img :src="activityMsg.imageIds" />
          </div>
          <div class="right_msg">
            <div class="name">
              {{ activityMsg.title }}
            </div>
            <div class="type">
              活动类型: {{ typeList[activityMsg.type] }} &nbsp;&nbsp;&nbsp;ID: {{ activityMsg.id }}
            </div>
          </div>
        </div>
        <div class="time">
          报名时间: {{ dayFormat(activityMsg.startTime) }} - {{ dayFormat(activityMsg.endTime) }}
        </div>
        <div class="time">
          活动时间: {{ dayFormat(activityMsg.showStarttime) }} - {{ dayFormat(activityMsg.showEndtime) }}
        </div>
        <div class="time">
          审核时间: 预计报名后 {{ activityMsg.auditTime }} 天内审核完成
        </div>
        <div class="time" v-if="activityMsg.type  === 6">
          <span>满减档位：每满{{ activityMsg.discountsCondition }} 减{{ activityMsg.discountsValue }}</span>
          <span class="interval">商家承担比例：{{ activityMsg.responsibilityRatio }} %</span>
        </div>
        <div class="last_time">
          <div class="is_record">
            报名资质:
          </div>
          <div v-if="activityMsg.isShow == 1">
            暂不对外开放活动报名
          </div>
          <div v-else>
            <div
              v-if="activityMsg.okOrNot"
              class="reason"
            >
              <div class="conform">
                符合
              </div>
              <el-progress
                :percentage="100"
                status="success"
              ></el-progress>

              <div v-if="activityMsg.applyOkOrNot">
                <!-- <router-link
                    :to="{path: '/nb/marketingTools/activity/apply',query: {id: $route.query.id, title: $route.query.title, storeId: $route.query.storeId}}"
                    style="color:#20a0ff;marginTop: 10px"
                    target="_blank"
                  >
                    立即报名
                  </router-link> -->
                <el-button
                  type="primary"
                  @click="onApply"
                >
                  立即报名
                </el-button>
              </div>
              <div v-if="!activityMsg.applyOkOrNot">
                不在报名时间内
              </div>
            </div>
            <div
              v-if="!activityMsg.okOrNot"
              class="reason"
            >
              <div class="conform">
                不符合
              </div>
              <el-progress
                :percentage="50"
                status="exception"
              ></el-progress>
              <el-button
                slot="reference"
                size="mini"
                type="primary"
                @click="viewReason"
              >
                查看原因
              </el-button>
            </div>
          </div>
        </div>
      </div>
      <el-tabs
        v-model="activeName"
        type="border-card"
        @tab-click="handleClick"
      >
        <el-tab-pane
          label="活动详情"
          name="first"
        >
          <div>
            <h2>{{ activityMsg.title }}</h2>
            <div
              class="detail_content"
              v-html="activityMsg.details"
            ></div>
          </div>
        </el-tab-pane>
        <el-tab-pane
          label="活动要求"
          name="second"
        >
          <QualificationRequirement
            v-if="activeName === 'second'"
            :id="$route.query.id"
            :store-id="$route.query.storeId"
          />
        </el-tab-pane>
        <!-- <el-tab-pane label="常见问题" name="third">
            <div class="commonPro">

<h2>1、如何操作报名？</h2>

<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra justo commodo. Proin sodales pulvinar sic tempor. Sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nam fermentum, nulla luctus pharetra vulputate, felis tellus mollis orci, sed rhoncus pronin sapien nunc accuan eget.</p>

<h2>2、同一个商品可以报名多少个活动？怎么排期</h2>

<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra justo commodo. Proin sodales pulvinar sic tempor. Sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nam fermentum, nulla luctus pharetra vulputate, felis tellus mollis orci, sed rhoncus pronin sapien nunc accuan eget.</p>
            </div>
          </el-tab-pane> -->

        <el-tab-pane
          label="活动商品"
          name="fourth"
        >
          <ActivityGoods
            v-if="activeName === 'fourth'"
            :active-name="activeName"
            :prom-id="Number($route.query.id)"
            :store-id="$route.query.storeId"
          />
        </el-tab-pane>
        <el-tab-pane
          label="已驳回商品"
          name="five"
        >
          <ActivityGoods
            v-if="activeName === 'five'"
            :active-name="activeName"
            :prom-id="Number($route.query.id)"
            :store-id="$route.query.storeId"
          />
        </el-tab-pane>
      </el-tabs>
    </page-content>
  </div>
</template>

<script>
import { toolbarOptions, typeOptions, typeList } from '../config'
import dayjs from 'dayjs'
import QualificationRequirement from './components/qualificationRequirement'
import ActivityGoods from './components/activityGoods.vue'

export default {
  name: 'ActivityDetail',
  components: {
    QualificationRequirement,
    ActivityGoods
  },
  data() {
    return {
      activeName: 'first',
      activityMsg: {

      },

      content: '', // 详情
      typeList
    }
  },
  watch: {

  },
  mounted() {
    this.getDetailInfo()
  },
  methods: {

    // 切换tab
    handleClick(tab, event) {
      console.log(tab, event)
    },

    onApply() {
      const { href } = this.$router.resolve({
        path: '/nb/marketingTools/activity/apply',
        query: { id: this.$route.query.id, title: this.$route.query.title, storeId: this.$route.query.storeId, activityType: this.activityMsg.type }
      })
      window.open(href, '_blank')
    },

    getDetailInfo() {
      this.$axios({
        method: 'get',
        url: this.$api.tradeProm.activity_detail,
        params: {
          tradePromId: this.$route.query.id,
          storeId: this.$route.query.storeId
          // storeId: 14736713995423744
        }
      }).then(res => {
        if (res.code === 0) {
          this.activityMsg = res.data
        }
      }).catch(error => {
        console.log(error)
      })
    },

    viewReason() {
      this.activeName = 'second'
    },

    dayFormat(val) {
      return dayjs(parseInt(val)).format('YYYY/MM/DD HH:mm:ss')
    }

  }
}
</script>
<style lang="scss" scoped>
.activity_detail_content {
  .activity_msg {
    .top_content {
        display: flex;
        .left_img {
            width: 80px;
            height: 80px;
           img {
             width: 100%;
             height: 100%;
           }
        }
        .right_msg {
             margin-left: 20px;
            .name {
              line-height: 30px;
              font-size: 16px;
              font-weight: 700;
            }
            .type {
                margin-top: 20px;
                color: #1890ff;
            }
        }
    }
    .time {
      line-height: 30px;
    }
    margin-bottom: 20px;
    .last_time {
      margin-top: 10px;
      display: flex;
      align-items: center;
      .is_record {
        margin-right: 10px;
      }
      .reason {
        width: 300px;
        .conform {
          line-height: 30px;
        }
        .el-progress {
          margin-bottom: 10px;
        }
      }
    }
  }
  p {
    line-height: 20px;
  }
  .commonPro {
    h2 {
      line-height: 40px;
    }
  }
  .interval {
    margin-left: 16px;
  }
  .detail_content {
    line-height: 20px;
    overflow: hidden;
    margin-top: 10px;
  }
}
</style>
