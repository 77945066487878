var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "activity_detail_content" },
    [
      _c(
        "page-content",
        [
          JSON.stringify(_vm.activityMsg) != "{}"
            ? _c("div", { staticClass: "activity_msg" }, [
                _c("div", { staticClass: "top_content" }, [
                  _c("div", { staticClass: "left_img" }, [
                    _c("img", { attrs: { src: _vm.activityMsg.imageIds } }),
                  ]),
                  _c("div", { staticClass: "right_msg" }, [
                    _c("div", { staticClass: "name" }, [
                      _vm._v(" " + _vm._s(_vm.activityMsg.title) + " "),
                    ]),
                    _c("div", { staticClass: "type" }, [
                      _vm._v(
                        " 活动类型: " +
                          _vm._s(_vm.typeList[_vm.activityMsg.type]) +
                          "    ID: " +
                          _vm._s(_vm.activityMsg.id) +
                          " "
                      ),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "time" }, [
                  _vm._v(
                    " 报名时间: " +
                      _vm._s(_vm.dayFormat(_vm.activityMsg.startTime)) +
                      " - " +
                      _vm._s(_vm.dayFormat(_vm.activityMsg.endTime)) +
                      " "
                  ),
                ]),
                _c("div", { staticClass: "time" }, [
                  _vm._v(
                    " 活动时间: " +
                      _vm._s(_vm.dayFormat(_vm.activityMsg.showStarttime)) +
                      " - " +
                      _vm._s(_vm.dayFormat(_vm.activityMsg.showEndtime)) +
                      " "
                  ),
                ]),
                _c("div", { staticClass: "time" }, [
                  _vm._v(
                    " 审核时间: 预计报名后 " +
                      _vm._s(_vm.activityMsg.auditTime) +
                      " 天内审核完成 "
                  ),
                ]),
                _vm.activityMsg.type === 6
                  ? _c("div", { staticClass: "time" }, [
                      _c("span", [
                        _vm._v(
                          "满减档位：每满" +
                            _vm._s(_vm.activityMsg.discountsCondition) +
                            " 减" +
                            _vm._s(_vm.activityMsg.discountsValue)
                        ),
                      ]),
                      _c("span", { staticClass: "interval" }, [
                        _vm._v(
                          "商家承担比例：" +
                            _vm._s(_vm.activityMsg.responsibilityRatio) +
                            " %"
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _c("div", { staticClass: "last_time" }, [
                  _c("div", { staticClass: "is_record" }, [
                    _vm._v(" 报名资质: "),
                  ]),
                  _vm.activityMsg.isShow == 1
                    ? _c("div", [_vm._v(" 暂不对外开放活动报名 ")])
                    : _c("div", [
                        _vm.activityMsg.okOrNot
                          ? _c(
                              "div",
                              { staticClass: "reason" },
                              [
                                _c("div", { staticClass: "conform" }, [
                                  _vm._v(" 符合 "),
                                ]),
                                _c("el-progress", {
                                  attrs: { percentage: 100, status: "success" },
                                }),
                                _vm.activityMsg.applyOkOrNot
                                  ? _c(
                                      "div",
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            attrs: { type: "primary" },
                                            on: { click: _vm.onApply },
                                          },
                                          [_vm._v(" 立即报名 ")]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                !_vm.activityMsg.applyOkOrNot
                                  ? _c("div", [_vm._v(" 不在报名时间内 ")])
                                  : _vm._e(),
                              ],
                              1
                            )
                          : _vm._e(),
                        !_vm.activityMsg.okOrNot
                          ? _c(
                              "div",
                              { staticClass: "reason" },
                              [
                                _c("div", { staticClass: "conform" }, [
                                  _vm._v(" 不符合 "),
                                ]),
                                _c("el-progress", {
                                  attrs: {
                                    percentage: 50,
                                    status: "exception",
                                  },
                                }),
                                _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      slot: "reference",
                                      size: "mini",
                                      type: "primary",
                                    },
                                    on: { click: _vm.viewReason },
                                    slot: "reference",
                                  },
                                  [_vm._v(" 查看原因 ")]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]),
                ]),
              ])
            : _vm._e(),
          _c(
            "el-tabs",
            {
              attrs: { type: "border-card" },
              on: { "tab-click": _vm.handleClick },
              model: {
                value: _vm.activeName,
                callback: function ($$v) {
                  _vm.activeName = $$v
                },
                expression: "activeName",
              },
            },
            [
              _c(
                "el-tab-pane",
                { attrs: { label: "活动详情", name: "first" } },
                [
                  _c("div", [
                    _c("h2", [_vm._v(_vm._s(_vm.activityMsg.title))]),
                    _c("div", {
                      staticClass: "detail_content",
                      domProps: { innerHTML: _vm._s(_vm.activityMsg.details) },
                    }),
                  ]),
                ]
              ),
              _c(
                "el-tab-pane",
                { attrs: { label: "活动要求", name: "second" } },
                [
                  _vm.activeName === "second"
                    ? _c("QualificationRequirement", {
                        attrs: {
                          id: _vm.$route.query.id,
                          "store-id": _vm.$route.query.storeId,
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "el-tab-pane",
                { attrs: { label: "活动商品", name: "fourth" } },
                [
                  _vm.activeName === "fourth"
                    ? _c("ActivityGoods", {
                        attrs: {
                          "active-name": _vm.activeName,
                          "prom-id": Number(_vm.$route.query.id),
                          "store-id": _vm.$route.query.storeId,
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "el-tab-pane",
                { attrs: { label: "已驳回商品", name: "five" } },
                [
                  _vm.activeName === "five"
                    ? _c("ActivityGoods", {
                        attrs: {
                          "active-name": _vm.activeName,
                          "prom-id": Number(_vm.$route.query.id),
                          "store-id": _vm.$route.query.storeId,
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }