<template>
  <div>
    <el-table
      v-loading="loading"
      :data="tableData"
      border
      :header-cell-style="{'background': '#D9DDE1', 'color': '#0D0202', 'font-weight': 400}"
      style="width: 100%"
      element-loading-text="拼命加载中"
    >
      <el-table-column
        label="商品ID"
        prop="goodsId"
      />
      <el-table-column
        prop="name"
        label="商品图片"
      >
        <template slot-scope="scope">
          <el-image
            style="width: 60px; min-height: 60px; color: #fff; display: flex; align-items: center;"
            :src="scope.row.imageUrls.split(',')[0]"
            :preview-src-list="scope.row.imageUrls.split(',')"
          />
        </template>
      </el-table-column>
      <el-table-column
        label="商品名称"
        prop="goodsName"
      />
      <!-- <el-table-column
        label="商品信息"
        min-width="210"
      >
        <template slot-scope="scope">
          <div class="goods_message">
            <div class="left">
              <img :src="scope.row.imageUrls" />
            </div>
            <div class="right">
              <div class="title">
                {{ scope.row.goodsName }}
              </div>
              <div class="id">
                库存: {{ scope.row.stockQuantity }}
              </div>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        label="原价(元)"
        min-width="110"
      >
        <template slot-scope="scope">
          <div v-if="scope.row.lowSellPrice || scope.row.highSellPrice">
            {{
              scope.row.lowSellPrice == scope.row.highSellPrice ? `${scope.row.highSellPrice}` : `${scope.row.lowSellPrice} - ${scope.row.highSellPrice}`
            }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        label="活动价(元)"
        min-width="110"
      >
        <template slot-scope="scope">
          <div v-if="scope.row.ancestryCategoryId === 2">/</div>
          <div v-else>
            <div v-if="scope.row.lowPromPrice || scope.row.highPromPrice">
              {{
                scope.row.lowPromPrice == scope.row.highPromPrice ? `${scope.row.lowPromPrice}` : `${scope.row.lowPromPrice} - ${scope.row.highPromPrice}`
              }}
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="promStock"
        label="活动库存"
        min-width="110"
      >
        <template slot-scope="{row}">
          <div v-if="row.ancestryCategoryId === 2">/</div>
          <div v-else>{{row.promStock}}</div>
        </template>      
      </el-table-column> -->
      <el-table-column
        label="操作"
        width="230"
        fixed="right"
        align="center"
      >
        <template slot-scope="scope">
          <!-- <el-button
            v-if="activeName == 'fourth'"
            size="mini"
            type="danger"
            plain
            @click="deleteRow(scope.row.id)"
          >
            移出活动
          </el-button> -->
          <el-popover
           v-if="activeName == 'five'"
            style="margin-bottom: 4px"
            :ref="`popover-${scope.$index}`"

            placement="bottom"
            width="200"
            title="审核原因:"
            trigger="click"
            :content="auditReason"
          >
            <el-button
              slot="reference"
              type="danger"
              plain
              size="mini"
              style="marginRight: 10px"
              @click="onViewReason(scope.row)"
            >
              查看原因
            </el-button>
          </el-popover>
          <el-button
            v-if="activeName == 'five'"
            style="margin-bottom: 4px"
            size="mini"
            type="danger"
            plain
            @click="onJoinOrEdit(scope.row, 'readd')"
          >
            重新报名
          </el-button>
          <el-button
            v-if="scope.row.ancestryCategoryId === 2 && !isCsfr"
            style="margin-bottom: 4px"
            size="mini"
            plain
            @click="onJoinOrEdit(scope.row, 'showHome')"
          >
            查看规格
          </el-button>
          <el-button
            v-if="scope.row.ancestryCategoryId != 2 && !isCsfr"
            style="margin-bottom: 4px"
            size="mini"
            plain
            @click="onJoinOrEdit(scope.row, 'show')"
          >
            查看规格
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page="pagination.page"
      :page-sizes="[10, 20, 30, 50]"
      :page-size="pagination.page_count"
      layout="total, sizes, prev, pager, next, jumper"
      :total="pagination.item_total"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />

    <change-goods-dialog
      v-if="joinDialogVisible"
      :join-dialog-visible.sync="joinDialogVisible"
      :operate-type="operateType"
      :operate-row-data="operateRowData"
      :prom-id="promId"
      @onupdatetable="updateTableData"
    />

    <!-- 名宿活动报名 -->
    <activeHome 
      :show.sync="showActiveHome" 
      :row="operateRowData" 
      :promId="$route.query.id"
      :auditStatus="0"
      @getList="getList" 
      :disabled="!showActiveBtn"
    />
  </div>
</template>

<script>
import changeGoodsDialog from './changeGoods.vue'
import activeHome from './activeHome.vue'

export default {
  name: 'ActivityGoods',
  components: {
    changeGoodsDialog,
    activeHome
  },
  props: {
    storeId: String,
    promId: Number,
    activeName: String

  },
  data() {
    return {
      tableData: [],
      loading: false,
      pagination: {
        page: 1,
        page_total: 0,
        item_total: 0,
        page_count: 10
      },
      auditReason: '', // 已驳回原因
      operateType: '',
      joinDialogVisible: false,
      operateRowData: {},

      showActiveHome: false, //展示名宿活动报名
      showActiveBtn: true,//展示报名按钮

    }
  },
  created() {
    this.getList()
  },
  computed: {
    isCsfr() {
      return this.$route.query.activityType === '6'
    }
  },
  methods: {
    // 分页控制
    handleSizeChange(val) {
      this.pagination.page_count = val
      this.pagination.page = 1
      this.getList()
    },
    handleCurrentChange(val) {
      this.pagination.page = val
      this.getList()
    },
    // 查看驳回原因
    onViewReason(row) {
      this.$axios(this.$api.tradeProm.get_reason, {
        params: {
          promGoodsId: row.id
        }
      }).then(res => {
        if (res.code === 0) {
          this.auditReason = res.data.auditReason
        }
      }).catch(error => {
        console.log(error)
      })
    },
    updateTableData() {
      this.pagination.page = 1
      this.getList()
    },
    // 重新报名
    onJoinOrEdit(row, type) {
      if(type === 'showHome') {//查看名宿
        this.operateRowData = row;
        this.showActiveHome = true;
        this.showActiveBtn = false;
        return
      }
      if(row.ancestryCategoryId == 2) {
        this.operateRowData = row;
        this.showActiveHome = true;
        this.showActiveBtn = true;
      }else {
        this.operateType = type
        this.joinDialogVisible = true
        this.operateRowData = row
      }
    },
    // 移出活动
    deleteRow(id) {
      this.$confirm('是否确认移出?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$axios({
          method: 'post',
          url: this.$api.tradeProm.cancel_apply,
          params: {
            promGoodsId: id
          }
        }).then(res => {
          if (res.code === 0) {
            this.$message.success('移出成功~')
            this.getList()
          }
        }).catch(error => {
          console.log(error)
        })
      })
    },

    getList() { // index为页面当前页数
      let _this = this
      _this.loading = true
      _this.$axios({
        method: 'get',
        url: _this.$api.tradeProm.activity_goods_list,
        params: {
          currPage: _this.pagination.page,
          pageSize: _this.pagination.page_count,
          auditStatus: _this.activeName == 'fourth' ? 1 : 2,
          storeId: _this.storeId,
          promId: _this.promId
        }
      }).then(function(res) {
        if (res.code === 0) {
          const { size, total, current, pages } = res.data
          _this.pagination = {
            page: Number(current),
            page_count: Number(size),
            page_total: Number(pages),
            item_total: Number(total)
          }
          _this.tableData = res.data.records
        } else {
          _this.tableData = []
          _this.pagination.item_total = 0
        }
        _this.loading = false
      }).catch(function(error) {
        console.log(error)
      })
    }

  }
}
</script>
<style lang="scss" scoped>
.qualification_content {
  h2 {
    font-size: 14px;
    font-weight: bolder;
    margin: 10px 0;
  }

  .line {
    border-bottom: 1px solid #999;
    border-right: 1px solid #999;
    text-align: center;
    line-height: 30px;
  }

  ul {
    border: 1px solid #999;
    width: 80%;
    margin: 0 auto;
    li {
      display: flex;
      align-items: center;
      height: 30px;
      div {
        border-bottom: 1px solid #999;
        border-right: 1px solid #999;
        text-align: center;
        line-height: 30px;
      }
      .title {
        width: 30%;
      }
      .requirement {
        width: 50%
      }
      .accord {
        width: 20%
      }
    }
  }
}

</style>
<style lang="scss">
.goods_message {
    display: inline-flex;
    align-items: center;
    justify-content: space-around;
    .left {
      margin: 10px 15px;
      img {
        width: 80px;
        height: 80px;
      }
    }
    .right {
      .title {
        font-weight: 700;
        margin: 10px 0;
      }
      .id {
        color: #999;
      }
    }
  }
</style>
