<template>
  <div class="qualification_content">
    <h2>店铺资质要求</h2>
    <ul v-if="JSON.stringify(storeRequirement) !== '{}'">
      <li>
        <div class="title">
          商家资质名称
        </div>
        <div class="requirement">
          资质要求
        </div>
        <div class="line">
          符合/不符合
        </div>
      </li>
      <li>
        <div class="title">
          供应商所属馆
        </div>
        <div class="requirement">
          {{ storeRequirement.siteName }}
        </div>
        <div class="line">
          {{ storeRequirement.siteIsOrNot == 0 ? '不符合' : '符合' }}
        </div>
      </li>
      <li>
        <div class="title">
          店铺经营时间
        </div>
        <div class="requirement">
          大于等于 {{ storeRequirement.storeOperationTime }}
        </div>
        <div class="line">
          {{ storeRequirement.operationTimeIsOrNot == 0 ? '不符合' : '符合' }}
        </div>
      </li>
      <li>
        <div class="title">
          店铺评分
        </div>
        <div class="requirement">
          大于等于 {{ storeRequirement.storeStar }}
        </div>
        <div class="line">
          {{ storeRequirement.storeStarIsOrNot == 0 ? '不符合' : '符合' }}
        </div>
      </li>
      <li>
        <div class="title">
          商家服务
        </div>
        <div class="requirement">
          大于等于 {{ storeRequirement.supplierStar }}
        </div>
        <div class="line">
          {{ storeRequirement.supplierStarIsOrNot == 0 ? '不符合' : '符合' }}
        </div>
      </li>
      <li>
        <div class="title">
          发货速度
        </div>
        <div class="requirement">
          大于等于 {{ storeRequirement.shippingStar }}
        </div>
        <div class="line">
          {{ storeRequirement.shippingStarIsOrNot == 0 ? '不符合' : '符合' }}
        </div>
      </li>
    </ul>
    <h2>商品资质要求</h2>
    <ul v-if="JSON.stringify(goodsRequirement) !== '{}'">
      <li>
        <div class="title">
          商品资质
        </div>
        <div class="requirement">
          资质要求说明
        </div>
      </li>
      <li>
        <div class="title">
          商品类目
        </div>
        <div class="requirement">
          {{ goodsRequirement.goodsLevelName }}
        </div>
      </li>
      <li>
        <div class="title">
          价格区间
        </div>
        <div class="requirement">
          {{ goodsRequirement.lowPrice }} - {{ goodsRequirement.highPrice }}
        </div>
      </li>
      <li>
        <div class="title">
          商品近30天的评价量
        </div>
        <div class="requirement">
          大于等于 {{ goodsRequirement.goodsCommentNum }}
        </div>
      </li>
      <li>
        <div class="title">
          商品的历史累计销量
        </div>
        <div class="requirement">
          大于等于 {{ goodsRequirement.salesSum }}
        </div>
      </li>
      <li>
        <div class="title">
          商品评分
        </div>
        <div class="requirement">
          大于等于 {{ goodsRequirement.goodsStar }}
        </div>
      </li>
      <li>
        <div class="title">
          商品包邮
        </div>
        <div class="requirement">
          {{ goodsRequirement.isShip == 0 ? '是' : '否' }}
        </div>
      </li>
    </ul>
    <div v-if="!isCsfr && (goodsRequirement?.discountsValue ||  goodsRequirement?.discountsValue)">
      <h2>商品报名要求</h2>
      <ul>
        <li v-if="goodsRequirement.discountsValue">
          <div class="title">
            活动折扣
          </div>
          <div class="requirement">
            折扣须不低于 {{ goodsRequirement.discountsValue * 100 /10 }} 折
          </div>
        </li>
        <li v-if="goodsRequirement.promStock">
          <div class="title">
            活动库存
          </div>
          <div class="requirement">
            库存须不低于 {{ goodsRequirement.promStock }}
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>

export default {
  name: 'QualificationRequirement',
  props: {
    id: String,
    storeId: String
  },
  data() {
    return {
      goodsRequirement: {},
      storeRequirement: {
        requirementSiteName: null,
        storeOperationTime: 0,
        storeStar: 0,
        supplierStar: 0,
        shippingStar: 0

      }
    }
  },
  computed: {
    isCsfr() {
      return this.$route.query.activityType === '6'
    }
  },
  created() {
    this.getGoods()
    this.getStore()
  },
  mounted() {

  },
  methods: {
    getGoods() {
      this.$axios(this.$api.tradeProm.goods_requirement, {
        params: {
          promId: this.id,
          storeId: this.storeId
        }
      }).then(res => {
        if (res.code === 0 && res.data != null) {
          this.goodsRequirement = res.data
        } else {
          this.$message.warning('获取商品资质数据为空')
        }
      }).catch(error => {
        console.log(error)
      })
    },
    getStore() {
      this.$axios(this.$api.tradeProm.store_requirement, {
        params: {
          promId: this.id,
          storeId: this.storeId
        }
      }).then(res => {
        if (res.code === 0 && res.data != null) {
          this.storeRequirement = res.data
        } else {
          this.$message.warning('获取店铺资质数据为空')
        }
      }).catch(error => {
        console.log(error)
      })
    }

  }
}
</script>
<style lang="scss" scoped>
.qualification_content {
  h2 {
    font-size: 14px;
    font-weight: bolder;
    margin: 10px 0;
  }

  ul {
    // border: 1px solid #999;
    border-left: 1px solid #999;
    // border-top: 1px solid #999;
    width: 80%;
    margin: 0 auto;
    li {
      display: flex;
      align-items: center;
      height: 30px;
      &:last-child {
        .title {
          border-bottom: 1px solid #999;
        }
        .requirement {
          border-bottom: 1px solid #999;
        }
        .line {
          border-bottom: 1px solid #999;
        }

      }
      div {
        border-right: 1px solid #999;
        border-top: 1px solid #999;
        text-align: center;
        line-height: 30px;
        height: 30px;
      }
      .title {
        width: 30%;
      }
      .requirement {
        width: 50%
      }
      .line {
        width: 20%;

      }
    }
  }
}
</style>
